body { background-color: #ffffff; }
body { color: var(--clr-643); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-645);
font-family: 'Cinzel';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-645);
font-family: 'Cinzel';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-645);
font-family: 'Cinzel';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-645);
font-family: 'Cinzel';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
color: var(--clr-645);
font-family: 'Cinzel';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
h6 {
color: var(--clr-645);
font-family: 'Cinzel';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 50px;

}
}
.button {
font-family: 'Noto Sans';
font-weight: 500;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Noto Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Noto Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Noto Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-644);}
a:hover {color: var(--clr-645);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-645);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-645);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-645);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-646);
color: var(--clr-643);
&:hover { color: var(--clr-645);}
padding: 10px 30px;

 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-646);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-646);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-647);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-647);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-647);
 }
.MES8 {
background-color: var(--clr-647);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-647);
 }
.MES9 {
background-color: var(--clr-647);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-643);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-643);
 }
.MES11 {
background-color: var(--clr-643);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-644);
color: #ffffff;
&:hover { color: var(--clr-645);}
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-644);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-644);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 14.4px;
 }
.MES14 {
font-size: 14.4px;
 }
cite.MEC14{
font-size: 14.4px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-647);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-643);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-647);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-643);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-643);
text-transform: capitalize;
}
 &:hover > a.MEC17{color: var(--clr-644);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
&.horizontal .menu-item.MEC17:hover { & > .sub-menu {transform: none !important;
 left: auto !important;
 text-align: right;
 right: 0;
}}
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}



&.horizontal .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-646-flat);}}
&.vertical .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-646-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC17{background-color: var(--clr-646); &:hover {background-color: 2;}
}
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-643);
}
 &:hover > a.MEC17{color: 4;
}
 }

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-648);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slick-slider{padding: 15px;}

@media #{$large-up} {
& .slick-slider{padding: 35px;}

}
& .slider-arrow {color: var(--clr-643);
font-size: 60px;
@media #{$medium-up} {
font-size: 60px;
};
@media #{$large-up} {
font-size: 60px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 9;
border-radius: 50%;
border-style:solid;
border-width:1px;
@media #{$medium-up} {
border-width: 1px;
};
@media #{$large-up} {
border-width: 1px;};
&:hover{border-color: 9;}
background-color: 11;
background-clip: padding-box;
&:hover {background-color: 4;}
width:1px;
height:1px;
@media #{$medium-up} {
width:1px;
height:1px;
};
@media #{$large-up} {
width:1px;
height:1px;
};
&:hover{background-color: 4;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-646);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-645);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-643);
color: #ffffff;
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
border-radius: 50px;
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-643);
font-size: 14.4px;
 }
.MES22 {
background-color: var(--clr-643);
font-size: 14.4px;
 }
cite.MEC22{
font-size: 14.4px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-647);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-647);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 6px 0 0 0;}
& > hr {border-color: var(--clr-646) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-645);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-643) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-645);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-643) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-645);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 50px; }; }
 }
a.MEC26 { color: var(--clr-645);
&:hover { color: var(--clr-649);}
 }
cite.MEC26{
color: var(--clr-645);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* White BG Transparent:27 */
.MES27 {
background-color: var(--clr-650);
 }
.MES27 {
background-color: var(--clr-650);
 }
/* Popup form Window:28 */
.MES28 {
background-color: var(--clr-647);
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-646);
 }
.MES28 {
background-color: var(--clr-647);
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-646);
 }
/* Exhibition rollover:29 */
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-647);}
 }
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-647);}
 }
/* SOLD Panel Red text:30 */
.MES30 {
color: var(--clr-644);
 }
.MES30 {
color: var(--clr-644);
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: var(--clr-644);
 }
 }
cite.MEC30{
color: var(--clr-644);
}
/* Header Banner:32 */
.MES32 {
background-color: var(--clr-647);
color: var(--clr-645);
border-width: 0 0 6px 0;
border-style: solid;
border-color: var(--clr-646);
 }
.MES32 {
background-color: var(--clr-647);
color: var(--clr-645);
border-width: 0 0 6px 0;
border-style: solid;
border-color: var(--clr-646);
 }
a.MEC32 { color: var(--clr-645);
&:hover { color: var(--clr-649);}
 }
cite.MEC32{
color: var(--clr-645);
}
/* Whaite background 10px padding:33 */
.MES33 {
background-color: #ffffff;
padding: 10px;

 }
.MES33 {
background-color: #ffffff;
padding: 10px;

 }
/* Footer Banner:34 */
.MES34 {
background-color: var(--clr-647);
color: var(--clr-645);
border-width: 6px 0 0 0;
border-style: solid;
border-color: var(--clr-646);
 }
.MES34 {
background-color: var(--clr-647);
color: var(--clr-645);
border-width: 6px 0 0 0;
border-style: solid;
border-color: var(--clr-646);
 }
a.MEC34 { color: var(--clr-645);
&:hover { color: var(--clr-649);}
 }
cite.MEC34{
color: var(--clr-645);
}
/* Hover Panel:36 */
.MES36 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-650);}
 }
.MES36 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-650);}
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: transparent;
 }
&:hover { h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: var(--clr-645);} }
 }
a.MEC36 { color: transparent;
&:hover { color: var(--clr-643);}
 }
/* :37 */
.MES37 {
background-color: var(--clr-644);
&:hover {background-color: var(--clr-648);}
border-radius: 8px;
border-width: 1px;
border-style: solid;
 }
/* :38 */
.MES38 {
font-size: 24.32px;
@media #{$medium-up} {
font-size: 28.08px;
};
@media #{$large-up} {
font-size: 28.98px;
};
 }
/* :39 */
.MES39 {
padding: 10px 30px;

 }
/* :40 */
.MES40 {
background-color: #0000ff;
color: #ffffff;
&:hover { color: #00ffff;}
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
padding: 25px;

border-width: 3px;
border-style: solid;
border-color: var(--clr-648);
 }
/* :41 */
.MES41 {
background-color: #008080;
&:hover {background-color: #00ffff;}
color: #ffffff;
border-radius: 15px;
padding: 31px;

 }
/* :42 */
.MES42 {
padding: 20px 15px;

 }
/* :43 */
.MES43 {
padding: 15px 15px 15px 45px;

 }
.MES43 {
padding: 15px 15px 15px 45px;

 }
/* :44 */
.MES44 {
padding: 15px 45px 15px 15px;

 }
.MES44 {
padding: 15px 45px 15px 15px;

 }
/* Panel message 1:45 */
.MES45 {
background-color: #e1f5fe;
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

 }
.MES45 {
background-color: #e1f5fe;
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

 }
